html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

textarea {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.public-DraftStyleDefault-block {
  margin-bottom: 1em;
}

/* Custom Scrollbar */
::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 8px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #D62929;
}

@media only screen and (max-width: 1024px) {
    .hiddenlessthan1024 {
        display: none !important;
    }
}

@media only screen and (min-width: 1024px) {
    .hiddengreaterthan1024 {
        display: none !important;
    }
}

@media only screen and (max-width: 800px) {
    .hiddenlessthan800 {
        display: none !important;
    }
}

@media only screen and (min-width: 801px) {
    .hiddengreaterthan800 {
        display: none !important;
    }
}

@media only screen and (max-width: 650px) {
    .hiddenlessthan650 {
        display: none !important;
    }
}

@media only screen and (min-width: 651px) {
    .hiddengreaterthan650 {
        display: none !important;
    }
}
